<template>
  <product title="Amazfit 表带 真皮系列"
    :swiper-data="swiperData"
    :slogan="slogan"
    price="229"
    :colors="colors"
    :relatived-products="relativedProducts"
    :detail-images="detailImages"
    :param-images="paramImages"
    isSwitchSlogan
    buy-link="https://shop18009901.m.youzan.com/wscgoods/detail/2ou32nj9b4039"
  ></product>
</template>

<script>
import { CDN_URL } from '@/config'
import Product from '../Product'

export default {
  name: 'leather-wristbands',
  components: {
    Product
  },
  data () {
    return {
      slogan: [
        '20mm',
        '20mm',
        '22mm',
        '22mm'
      ],
      colors: [
        '经典黑',
        '铜棕色',
        '经典黑',
        '栗棕色'
      ],
      swiperData: [
        [
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part03/2_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part03/2_pro_02.png'
        ],
        [
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part03/3_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part03/3_pro_02.png'
        ],
        [
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part03/4_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part03/4_pro_02.png'
        ],
        [
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part03/5_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part03/5_pro_02.png'
        ]
      ],
      relativedProducts: [
        {
          name: 'Amazfit<br/>炫彩硅胶表带',
          image: CDN_URL + '/images/product/accessory/01.png',
          price: 59
        },
        {
          name: '米动手表青春版<br/>彩色腕带',
          image: CDN_URL + '/images/product/accessory/02.png',
          price: 49
        },
        {
          name: 'Amazfit<br/>氟橡胶表带',
          image: CDN_URL + '/images/product/accessory/03.png',
          price: 129
        },
        {
          name: 'Amazfit 表带<br/>真皮系列',
          image: CDN_URL + '/images/product/accessory/04.png',
          price: 129
        }
      ],
      detailImages: [
        'https://huami-site.cdn.bcebos.com/files/amazfit/cn/sku/parts/part03/detail/mobile.jpg'
      ],
      paramImages: [
        'https://huami-site.cdn.bcebos.com/files/amazfit/cn/sku/parts/part03/detail/parameter.jpg'
      ]
    }
  },
  methods: {

  }
}
</script>

<style>
</style>
